import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function Overlay({
  children,
  open,
  setOpen,
  onClose,
  className = "",
  title,
}) {
  const closeOverlay = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  return (
    <Transition show={open}>
      <Dialog className="relative z-20" onClose={closeOverlay}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="block sm:flex max-h-full w-full max-w-full sm:min-h-full sm:min-w-full items-end justify-center p-4 text-center sm:items-center sm:p-4">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-h-dialog sm:my-8 sm:w-full sm:max-w-5xl">
                <div className="absolute z-50 right-0 top-0 pr-3 pt-3 sm:pr-9 sm:pt-6 block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => closeOverlay()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={`max-h-dialog overflow-hidden overflow-y-auto`}>
                  <div className={className}>
                    {title && (
                      <Fragment>
                        <DialogTitle className="text-lg mt-5 sm:mt-0">
                          {title}
                        </DialogTitle>
                        <hr className="my-2 opacity-50" />
                      </Fragment>
                    )}
                    {children}
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
