import React, { useState, useContext } from "react";
import { GiFlyingFlag } from "react-icons/gi";
import { ImSpinner2 } from "react-icons/im";
import { AppContext } from "../../context/AppContext";
import Overlay from "../Overlay";

function SectorTime({ id, settings = {}, styleId = null, racer }) {
  const { race } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  //race.flagcolor;
  return !race ? (
    <ImSpinner2 className="animate-spin text-5xl" />
  ) : (
    <>
      <div
        className="flex flex-col w-full h-full cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div
          className={`text-base text-center ${
            settings.Size == 1 ? `pt-4` : ``
          }`}
        >
          {race.status}
        </div>
        <GiFlyingFlag
          style={{ color: race.flagcolor }}
          className={`drop-shadow-md m-auto ${
            settings.Size == 1 ? `text-5xl` : `text-9xl`
          }`}
        />
      </div>
      <Overlay
        open={isOpen}
        setOpen={setIsOpen}
        title={`Race Details`}
        className="p-6"
      >
        Coming soon: A list of all penalties and messages.
      </Overlay>
    </>
  );
}

export default SectorTime;
