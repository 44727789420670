import React, { useEffect, useCallback, createContext, useState } from "react";
import { debounce } from "lodash";
import { WidgetSchema } from "../components/widgets/index";
import { SaveSelectedTemplate, GetSelectedTemplate } from "../helpers/Template";
import { GetTemplate } from "../helpers/Template";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSummary,
  fetchLaps,
  fetchRaces,
  fetchRace,
} from "../redux/slice/fetchData";
import { checkAuth } from "../redux/slice/Auth";

export const AppContext = createContext();

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function AppProvider({ children }) {
  const dispatch = useDispatch();

  const fetchData = useSelector((state) => state?.fetchData);
  const auth = useSelector((state) => state?.auth);
  const racesFromState = useSelector((state) => state?.fetchData?.races || []);

  const lapSummaryFromState = useSelector(
    (state) => state?.fetchData?.lapSummary || []
  );
  const selectedRacerFromState = useSelector(
    (state) => state?.fetchData?.selectedRacer || null
  );
  const raceFromState = useSelector((state) => state?.fetchData?.race || []);
  const lapsFromState = useSelector((state) => state?.fetchData?.laps || []);

  const [selectedRaceId, setSelectedRaceId] = useState(
    fetchData?.selectedRaceId
  );
  const [jwt, setJwt] = useState(() => getCookie("AuthToken"));
  const [authenticated, setAuthenticated] = useState(auth?.authenticated);
  const [races, setRaces] = useState(racesFromState);
  const [race, setRace] = useState(raceFromState);
  const [dataColumns, setDataColumns] = useState(6);
  const [tableRows, setTableRows] = useState(6);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showPinnedTemplateButtons, setShowPinnedTemplateButtons] =
    useState(true);
  const [showTireSection, setShowTireSection] = useState(false);
  const [isCreateWidgetsSequentially, setIsCreateWidgetsSequentially] =
    useState(false);

  const [clickedTemplate, setClickedTemplate] = useState(null);
  const [lapSummary, setLapSummary] = useState(lapSummaryFromState);
  const [laps, setLaps] = useState(lapsFromState);
  const [selectedRacer, setSelectedRacer] = useState(selectedRacerFromState);
  const [isRevertWindowOpen, setRevertWindowOpen] = useState(false);
  const [currentTemplateName, setCurrentTemplateName] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(() =>
    GetSelectedTemplate()
  ); // TODO: Load from localStorage
  const [widgets, setWidgets] = useState([]);
  const [pinnedTemplates, setPinnedTemplates] = useState(() =>
    JSON.parse(localStorage.getItem("pinnedTemplates") || "[]")
  );
  useEffect(() => {
    setSelectedRaceId(fetchData?.selectedRaceId);
  }, [fetchData?.selectedRaceId]);
  useEffect(() => {
    setRaces(racesFromState);
  }, [racesFromState]);

  useEffect(() => {
    setRace(raceFromState);
  }, [raceFromState]);

  useEffect(() => {
    setLapSummary(lapSummaryFromState);
  }, [lapSummaryFromState]);

  useEffect(() => {
    setLaps(lapsFromState);
  }, [lapsFromState]);

  useEffect(() => {
    setSelectedRacer(selectedRacerFromState);
  }, [selectedRacerFromState]);

  useEffect(() => {
    setAuthenticated(auth?.authenticated);
  }, [auth?.authenticated]);

  useEffect(() => {
    const checkAuthFunction = async () => {
      dispatch(checkAuth({ jwt: getCookie("AuthToken") }));

      // try {
      //   const response = await fetch("/api/auth", {
      //     headers: {
      //       Authorization: `Bearer ${jwt}`,
      //     },
      //   });
      //   const data = await response.json();
      //   setAuthenticated(response.ok);
      //   if (!response.ok) {
      //     console.log("Not Authenticated:", data);
      //     window.location.replace("http://trackstance.com/api/sso.php");
      //   }
      // } catch (error) {
      //   console.error("Failed to authenticate:", error);
      // }
    };

    checkAuthFunction();
    LoadAppContext();
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    if (!authenticated) return;
    const fetchRacesFunction = async () => {
      dispatch(fetchRaces({ jwt: getCookie("AuthToken"), selectedRaceId }));
      // try {
      //   const response = await fetch("/api/races", {
      //     headers: {
      //       Authorization: `Bearer ${jwt}`,
      //     },
      //   });
      //   const data = await response.json();
      //   // TODO: If new races then show a dialog with button to load them. and toggle a setting that will automatically go to newly added race
      //   setRaces(data);
      //   if (data.length > 0) {
      //     // If the currently selected race is not the latest one, check if it still exists in the list
      //     const raceExists = data.some((race) => race.id === selectedRaceId);
      //     if (!raceExists || selectedRaceId === null) {
      //       setSelectedRaceId(data[0].id); // Default to the first race if no race is selected or if it no longer exists
      //     }
      //   }
      // } catch (error) {
      //   console.error("Failed to fetch races:", error);
      // }
    };

    fetchRacesFunction();
    const intervalId = setInterval(fetchRacesFunction, 60000); // Refresh data every 60 seconds

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [selectedRaceId, authenticated]);

  useEffect(() => {
    if (!selectedRaceId || !authenticated) return;
    const fetchRaceFunction = async () => {
      dispatch(
        fetchRace({
          selectedRaceId: selectedRaceId,
          jwt: getCookie("AuthToken"),
        })
      );
      //   try {
      //     const response = await fetch(`/api/races?race_id=${selectedRaceId}`, {
      //       headers: {
      //         Authorization: `Bearer ${jwt}`,
      //       },
      //     });
      //     const data = await response.json();
      //     if (data.length > 0) {
      //       setRace(data[0]);
      //     }
      //   } catch (error) {
      //     console.error("Failed to fetch race:", error);
      //   }
    };

    fetchRaceFunction();
    const intervalId = setInterval(fetchRaceFunction, 5000); // Refresh data every 5 seconds

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [selectedRaceId, authenticated]);

  useEffect(() => {
    if (!selectedRaceId || !authenticated) return;

    const fetchSummaryFunction = async () => {
      dispatch(
        fetchSummary({
          selectedRaceId: selectedRaceId,
          jwt: getCookie("AuthToken"),
        })
      );
    };

    // const fetchSummary = async () => {
    //   try {
    //     const response = await fetch(`/api/summary?race_id=${selectedRaceId}`, {
    //         headers: {
    //             Authorization: `Bearer ${jwt}`
    //         }
    //     });
    //     const data = await response.json();
    //     setLapSummary(data);
    //     if (data) {
    //       if (data.some(racer => racer.name === selectedRacer)) {
    //         setSelectedRacer(selectedRacer);
    //       } else if (data[0]) {
    //         setSelectedRacer(data[0].name);
    //       }
    //     }
    //   } catch (error) {
    //     console.error('Failed to fetch summary:', error);
    //   }
    // };

    fetchSummaryFunction();
    const intervalId = setInterval(fetchSummaryFunction, 5000); // Refresh data every 5 seconds

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [selectedRaceId, authenticated, selectedRacer]);

  useEffect(() => {
    if (!selectedRaceId || !authenticated) return;
    const fetchLapsFunction = async () => {
      dispatch(
        fetchLaps({
          selectedRaceId: selectedRaceId,
          jwt: getCookie("AuthToken"),
        })
      );
      //   try {
      //     const response = await fetch(`/api/laps?race_id=${selectedRaceId}`, {
      //       headers: {
      //         Authorization: `Bearer ${jwt}`,
      //       },
      //     });
      //     const data = await response.json();
      //     setLaps(data);
      //   } catch (error) {
      //     console.error("Failed to fetch laps:", error);
      //   }
    };

    fetchLapsFunction();
  }, [lapSummary]);

  const setWidget = (id, widget) => {
    // Correctly merge with existing widget state to prevent overwriting
    setWidgets((current) => ({
      ...current,
      [id]: widget,
    }));
  };

  const GetWidgetSettings = (
    widgetId,
    mergeWithSchema = "",
    mergeObject = {}
  ) => {
    const parsedSettings =
      !widgets || !widgets[widgetId] ? {} : widgets[widgetId];

    for (const key in mergeObject) {
      parsedSettings[key] = parsedSettings.hasOwnProperty(key)
        ? parsedSettings[key]
        : mergeObject[key];
    }

    if (mergeWithSchema && mergeWithSchema.length > 0) {
      const defaultSettings = WidgetSchema["WidgetBase"];
      const mergedSettings = {};
      for (const key in defaultSettings) {
        mergedSettings[key] = parsedSettings.hasOwnProperty(key)
          ? parsedSettings[key]
          : defaultSettings[key].default;
      }

      const schemaSettings = WidgetSchema[mergeWithSchema];
      if (!schemaSettings) return parsedSettings;

      for (const key in schemaSettings) {
        mergedSettings[key] = parsedSettings.hasOwnProperty(key)
          ? parsedSettings[key]
          : schemaSettings[key].default;
      }

      mergedSettings.type = mergeWithSchema;
      return mergedSettings;
    } else {
      parsedSettings.type = mergeWithSchema;
      return parsedSettings;
    }
  };
  const setWidgetType = (id, type) => {
    // Correctly merge with existing widget state to prevent overwriting
    let widgetSettings = GetWidgetSettings(id, type);
    setWidget(id, widgetSettings);
  };

  const removeWidget = (id) => {
    setWidgets((currentWidgets) => {
      const updatedWidgets = { ...currentWidgets };
      delete updatedWidgets[id];
      return updatedWidgets;
    });
  };

  useEffect(() => {
    DeserializeAppContext(SaveSelectedTemplate(currentTemplate));
  }, [currentTemplate]);

  useEffect(() => {
    setCurrentTemplate(GetTemplate(currentTemplateName));
  }, [currentTemplateName]);

  useEffect(() => {
    if (hasLoaded) {
      SaveAppContext();
    }
  }, [
    selectedRacer,
    widgets,
    dataColumns,
    tableRows,
    showTireSection,
    showPinnedTemplateButtons,
    isCreateWidgetsSequentially,
  ]);

  const SerializeAppContext = function () {
    return {
      widgets: widgets,
      selectedRacer: selectedRacer,
      isRevertWindowOpen: isRevertWindowOpen,
      currentTemplateName: currentTemplateName,
      dataColumns: dataColumns,
      tableRows: tableRows,
      showTireSection: showTireSection,
      showPinnedTemplateButtons: showPinnedTemplateButtons,
      isCreateWidgetsSequentially: isCreateWidgetsSequentially,
    };
  };

  const DeserializeAppContext = function (data) {
    console.log("Deserializing:", data);
    if (!data) return;
    !(data.widgets == null) && setWidgets(data.widgets);
    !(data.selectedRacer == null) && setSelectedRacer(data.selectedRacer);
    !(data.isRevertWindowOpen == null) &&
      setRevertWindowOpen(data.isRevertWindowOpen);
    !(data.name == null) && setCurrentTemplateName(data.name);
    !(data.dataColumns == null) && setDataColumns(data.dataColumns);
    !(data.tableRows == null) && setTableRows(data.tableRows);
    !(data.showTireSection == null) && setShowTireSection(data.showTireSection);
    !(data.showPinnedTemplateButtons == null) &&
      setShowPinnedTemplateButtons(data.showPinnedTemplateButtons);
    !(data.isCreateWidgetsSequentially == null) &&
      setIsCreateWidgetsSequentially(data.isCreateWidgetsSequentially);
  };

  const GenerateTemplate = async function (prompt) {
    // Send POST with prompt as aiPrompt variable to /api/generate-widget
    if (!authenticated) return;
    try {
      const response = await fetch(
        "/api/generate-widget?prompt=" + encodeURIComponent(prompt),
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return await response.json();
    } catch (error) {
      console.error("Failed to generate template:", error);
    }
  };

  const SerializeTemplateContext = function (templateName) {
    const data = SerializeAppContext();
    return {
      name: templateName,
      widgets: data.widgets,
      dataColumns: data.dataColumns,
      tableRows: data.tableRows,
      showTireSection: data.showTireSection,
      showPinnedTemplateButtons: data.showPinnedTemplateButtons,
      isCreateWidgetsSequentially: data.isCreateWidgetsSequentially,
    };
  };

  const SaveAppContext = function () {
    const context = SerializeAppContext();
    localStorage.setItem("appContext", JSON.stringify(context));
    return context;
  };

  const LoadAppContext = function () {
    const data = JSON.parse(localStorage.getItem("appContext"));
    if (data) {
      DeserializeAppContext(data);
    }
  };

  return (
    <AppContext.Provider
      value={{
        GenerateTemplate,
        setCurrentTemplateName,
        GetWidgetSettings,
        SerializeAppContext,
        SerializeTemplateContext,
        DeserializeAppContext,
        SaveAppContext,
        LoadAppContext,
        widgets,
        pinnedTemplates,
        setPinnedTemplates,
        setWidget,
        setWidgets,
        setWidgetType,
        removeWidget,
        selectedRacer,
        dataColumns,
        currentTemplate,
        setCurrentTemplateName,
        setDataColumns,
        tableRows,
        setTableRows,
        showTireSection,
        setShowTireSection,
        race,
        races,
        selectedRaceId,
        setSelectedRaceId,
        lapSummary,
        laps,
        setSelectedRacer,
        showPinnedTemplateButtons,
        setShowPinnedTemplateButtons,
        isRevertWindowOpen,
        setRevertWindowOpen,
        clickedTemplate,
        setClickedTemplate,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
