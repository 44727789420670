import React, { useState, useEffect } from "react";
import StringHelper from "../../helpers/string";

const CountdownTimer = ({ id, racer, settings = {}, styleId = null }) => {
  let targetSecondsFromNow = 35;
  if (racer && racer.last_crossed_start && racer.avg_lap_time) {
    let last_crossed_start = new Date(racer.last_crossed_start);
    last_crossed_start.setHours(last_crossed_start.getHours() - 4);
    const next_crossed_start = new Date(
      last_crossed_start.getTime() + racer.avg_lap_time * 1000
    );
    targetSecondsFromNow =
      (next_crossed_start.getTime() - new Date().getTime()) / 1000;
  }
  const [timeLeft, setTimeLeft] = useState(
    getTimeDifference(targetSecondsFromNow)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = getTimeDifference(targetSecondsFromNow);
      setTimeLeft(newTimeLeft);
      if (newTimeLeft.total <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetSecondsFromNow]);

  function getTimeDifference(endSecondsFromNow) {
    const endTime = new Date();
    endTime.setTime(endTime.getTime() + endSecondsFromNow * 1000);
    const total = Date.parse(endTime) - Date.parse(new Date());
    let seconds = total / 1000;
    seconds = seconds < 0 ? 0 : seconds;

    return StringHelper.formatTime(seconds, 0);
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div>Est Lap Time Left</div>
      <p>{timeLeft}</p>
    </div>
  );
};

export default CountdownTimer;
