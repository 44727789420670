import { configureStore } from "@reduxjs/toolkit";
import fetchDataReducer from "./slice/fetchData";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import authReducer from "./slice/Auth";
// Persist Config
const persistConfig = {
  key: "root",
  storage,
};

// Combine Reducers
const rootReducer = combineReducers({
  fetchData: fetchDataReducer,
  auth: authReducer,
});

// Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Store
export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
