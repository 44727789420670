import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchLaps = createAsyncThunk(
  "fetchData/fetchLaps",
  async ({ selectedRaceId, jwt }) => {
    try {
      const response = await fetch(`/api/laps?race_id=${selectedRaceId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to fetch laps:", error);
      return [];
    }
  }
);

export const fetchRaces = createAsyncThunk(
  "fetchData/fetchRaces",
  async ({ jwt, selectedRaceId }, { dispatch }) => {
    try {
      const response = await fetch("/api/races", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to fetch races:", error);
    }
  }
);
export const fetchRace = createAsyncThunk(
  "fetchData/fetchRace",
  async ({ selectedRaceId, jwt }) => {
    try {
      const response = await fetch(`/api/races?race_id=${selectedRaceId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await response.json();
      if (data.length > 0) {
        return data[0];
      }
      return null;
    } catch (error) {
      console.error("Failed to fetch race:", error);
      return null;
    }
  }
);
export const fetchSummary = createAsyncThunk(
  "fetchData/fetchSummary",
  async ({ selectedRaceId, jwt }) => {
    try {
      const response = await fetch(`/api/summary?race_id=${selectedRaceId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      //console.error("Failed to fetch summary:", error);
      return [];
    }
  }
);

const fetchDataSlice = createSlice({
  name: "fetchData",
  initialState: {
    laps: [],
    races: [],
    race: [],
    lapSummary: [],
    selectedRaceId: null,
    selectedRacer: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLaps.fulfilled, (state, action) => {
      state.laps = action.payload;
    });
    builder.addCase(fetchLaps.rejected, (state, action) => {
      // state.laps = [];
    });
    builder.addCase(fetchLaps.pending, (state, action) => {
      // state.laps = [];
    });
    builder.addCase(fetchRaces.rejected, (state, action) => {
      // state.races = [];
    });
    builder.addCase(fetchRaces.pending, (state, action) => {
      // state.races = [];
    });
    builder.addCase(fetchRaces.fulfilled, (state, action) => {
      state.races = action.payload;
      if (action.payload?.length > 0) {
        // If the currently selected race is not the latest one, check if it still exists in the list
        const raceExists = action.payload.some(
          (race) => race.id === state.selectedRaceId
        );
        if (!raceExists || state.selectedRaceId === null) {
          state.selectedRaceId = action.payload[0]?.id; // Default to the first race if no race is selected or if it no longer exists
        }
      }
    });

    builder.addCase(fetchRace.fulfilled, (state, action) => {
      state.race = action.payload;
    });
    builder.addCase(fetchRace.rejected, (state, action) => {
      // state.race = [];
    });
    builder.addCase(fetchRace.pending, (state, action) => {
      // state.race = [];
    });
    builder.addCase(fetchSummary.fulfilled, (state, action) => {
      state.lapSummary = action?.payload;
      if (action.payload) {
        if (
          action.payload.some((racer) => racer?.name === state.selectedRacer)
        ) {
          state.selectedRacer = state.selectedRacer;
        } else if (action.payload[0]) {
          state.selectedRacer = action.payload[0]?.name;
        }
      }
    });
    builder.addCase(fetchSummary.rejected, (state, action) => {
      // state.lapSummary = [];
    });
    builder.addCase(fetchSummary.pending, (state, action) => {
      // state.lapSummary = [];
    });
  },
});

export default fetchDataSlice.reducer;
