import React, { useState, useContext, useEffect } from "react";
import Overlay from "../Overlay";
import { MdOutlineAddCircle } from "react-icons/md";
import { ImSpinner2 } from "react-icons/im";
import {
  GetDataPointAccessor,
  FormatDataPoint,
  InvertSortDatapoints,
} from "./index";
import StringHelper from "../../helpers/string";
import { AppContext } from "../../context/AppContext";
import Table from "../Table";
import { TabList, Tab } from "../Tabs";
import SelectInput from "../SelectInput";
import { SortLapSummary } from "../../helpers/LapSummary";

function DualDatapoint({ id, settings = {}, styleId = null, racer }) {
  const { lapSummary, laps } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedLapsRacer, setSelectedLapsRacer] = useState(() => racer?.name);
  const [selectedLapsRacer2, setSelectedLapsRacer2] = useState(
    () => racer?.name
  );

  useEffect(() => {
    if (racer) setSelectedLapsRacer(racer?.name);
    if (racer) setSelectedLapsRacer2(racer?.name);
  }, [isOpen, isOpen2]);

  let highlightRowRules = [];
  let hideRowRules = [];
  if (
    !settings.hasOwnProperty("Data_Point_Top") ||
    !settings.hasOwnProperty("Data_Point_Bottom")
  )
    return <ImSpinner2 className="animate-spin text-5xl" />;

  let tableCols = [
    { Accessor: "position" },
    { Accessor: "name", Mutate: (value) => StringHelper.abbreviateName(value) },
    {
      Accessor: GetDataPointAccessor(settings.Data_Point_Top),
      Header: `${settings.Data_Point_Top.replace(/_/g, " ")}`,
      Mutate: (value) => FormatDataPoint(settings.Data_Point_Top, value, true),
    },
  ];
  let lapTableCols = [
    { Accessor: "lapindex", Header: "Lap" },
    {
      Accessor: GetDataPointAccessor(settings.Data_Point_Top, true),
      Header: settings.Data_Point_Top.replace(/_/g, " "),
      Mutate: (value) => FormatDataPoint(settings.Data_Point_Top, value, true),
    },
  ];
  let lapTableCols2 = [
    { Accessor: "lapindex", Header: "Lap" },
    {
      Accessor: GetDataPointAccessor(settings.Data_Point_Bottom, true),
      Header: settings.Data_Point_Bottom.replace(/_/g, " "),
      Mutate: (value) => FormatDataPoint(settings.Data_Point_Top, value, true),
    },
  ];
  let hideRowRules2 = [];
  let tableCols2 = [
    { Accessor: "position" },
    { Accessor: "name", Mutate: (value) => StringHelper.abbreviateName(value) },
    {
      Accessor: GetDataPointAccessor(settings.Data_Point_Bottom),
      Header: `${settings.Data_Point_Bottom.replace(/_/g, " ")}`,
      Mutate: (value) =>
        FormatDataPoint(settings.Data_Point_Bottom, value, true),
    },
  ];
  let racersSortedByDatapoint;
  let racersSortedByDatapoint2;
  let currentDatapoint = 0;
  let currentDatapoint2 = 0;
  let invertSort = InvertSortDatapoints.includes(settings.Data_Point_Top);
  let invertSort2 = InvertSortDatapoints.includes(settings.Data_Point_Bottom);
  if (racer) {
    racersSortedByDatapoint = SortLapSummary(
      lapSummary,
      GetDataPointAccessor(settings.Data_Point_Top),
      invertSort
    );
    racersSortedByDatapoint2 = SortLapSummary(
      lapSummary,
      GetDataPointAccessor(settings.Data_Point_Bottom),
      invertSort2
    );
    currentDatapoint = parseFloat(
      racer[GetDataPointAccessor(settings.Data_Point_Top)]
    );
    hideRowRules = [
      {
        Accessor: [GetDataPointAccessor(settings.Data_Point_Top)],
        Value: currentDatapoint,
        Comparator: invertSort ? "<" : ">",
      },
      {
        Accessor: [GetDataPointAccessor(settings.Data_Point_Top)],
        Value: 0,
        Comparator: "==",
        parseMethod: parseFloat,
      },
    ];
    currentDatapoint2 = parseFloat(
      racer[GetDataPointAccessor(settings.Data_Point_Bottom)]
    );
    hideRowRules2 = [
      {
        Accessor: [GetDataPointAccessor(settings.Data_Point_Bottom)],
        Value: currentDatapoint2,
        Comparator: invertSort2 ? "<" : ">",
      },
      {
        Accessor: [GetDataPointAccessor(settings.Data_Point_Bottom)],
        Value: 0,
        Comparator: "==",
        parseMethod: parseFloat,
      },
    ];
    highlightRowRules = [{ Accessor: "name", Value: racer?.name }];
  }
  return !racer ? (
    <ImSpinner2 className="animate-spin text-5xl" />
  ) : (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="text-base">
          {StringHelper.abbreviateName(racer.name)}
        </div>
        <div
          className="cursor-pointer text-2xl"
          onClick={() => setIsOpen(true)}
        >
          {settings.Data_Point_Top.replace(/_/g, " ")}
        </div>
        <div
          className="cursor-pointer text-3xl md:text-2xl z-20 my-1"
          onClick={() => setIsOpen(true)}
        >
          <MdOutlineAddCircle
            className={`${
              settings.Theme === "Simple" ? `hidden ` : ``
            }inline-block`}
          />{" "}
          {FormatDataPoint(settings.Data_Point_Top, racer)}
        </div>
        <div
          className="cursor-pointer text-2xl"
          onClick={() => setIsOpen2(true)}
        >
          {settings.Data_Point_Bottom.replace(/_/g, " ")}
        </div>
        <div
          className="cursor-pointer text-3xl md:text-2xl z-20 my-1"
          onClick={() => setIsOpen2(true)}
        >
          <MdOutlineAddCircle
            className={`${
              settings.Theme === "Simple" ? `hidden ` : ``
            }inline-block`}
          />{" "}
          {FormatDataPoint(settings.Data_Point_Bottom, racer)}
        </div>
      </div>
      <Overlay
        open={isOpen}
        setOpen={setIsOpen}
        title={`${StringHelper.abbreviateName(
          racer.name
        )} ${settings.Data_Point_Top.replace(/_/g, " ")} Overview`}
        className="p-6"
      >
        <TabList
          navClassName="sticky top-0 py-4 pb-0 w-full bg-white"
          contentClassName="overflow-hidden p-6 pt-3"
        >
          <Tab name="Currently Faster than">
            <Table
              data={racersSortedByDatapoint}
              columns={tableCols}
              highlightRowRules={highlightRowRules}
              hideRowRules={hideRowRules}
              lightBg={true}
            />
          </Tab>
          <Tab name="Last Lap">
            <Table
              data={racersSortedByDatapoint}
              columns={tableCols}
              highlightRowRules={highlightRowRules}
              lightBg={true}
            />
          </Tab>
          <Tab name="All Laps">
            <SelectInput
              label="Rider"
              options={lapSummary.map((value) => ({ value: value.name }))}
              selected={selectedLapsRacer}
              onChange={(e) => setSelectedLapsRacer(e)}
            />
            <Table
              data={laps.filter((value) => value.name === selectedLapsRacer)}
              columns={lapTableCols}
              lightBg={true}
            />
          </Tab>
        </TabList>
      </Overlay>
      <Overlay
        open={isOpen2}
        setOpen={setIsOpen2}
        title={`${settings.Data_Point_Bottom.replace(/_/g, " ")} Overview`}
        className="p-6"
      >
        <TabList
          navClassName="sticky top-0 py-4 pb-0 w-full bg-white"
          contentClassName="overflow-hidden p-6 pt-3"
        >
          <Tab name={`Currently Faster than ${racer.name}`}>
            <Table
              data={racersSortedByDatapoint2}
              columns={tableCols2}
              highlightRowRules={highlightRowRules}
              hideRowRules={hideRowRules2}
              lightBg={true}
            />
          </Tab>
          <Tab name="Last Lap">
            <Table
              data={racersSortedByDatapoint2}
              columns={tableCols2}
              highlightRowRules={highlightRowRules}
              lightBg={true}
            />
          </Tab>
          <Tab name="All Laps">
            <SelectInput
              label="Rider"
              options={lapSummary.map((value) => ({ value: value.name }))}
              selected={selectedLapsRacer2}
              onChange={(e) => setSelectedLapsRacer2(e)}
            />
            <Table
              data={laps.filter((value) => value.name === selectedLapsRacer)}
              columns={lapTableCols2}
              lightBg={true}
            />
          </Tab>
        </TabList>
      </Overlay>
    </>
  );
}

export default DualDatapoint;
