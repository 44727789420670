import React from "react";
import { AppProvider } from "./context/AppContext";
import LiveTiming from "./components/LiveTiming";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <PersistGate loading={null} persistor={persistor}>
          <LiveTiming />
        </PersistGate>
      </AppProvider>
    </Provider>
  );
}

export default App;
