import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const checkAuth = createAsyncThunk(
  "auth/checkAuth",
  async ({ jwt }, { dispatch }) => {
    try {
      const response = await fetch("/api/auth", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        console.log("Not Authenticated:", data);
        window.location.replace("http://trackstance.com/api/sso.php");
      }
      return response.ok;
    } catch (error) {
      console.error("Failed to authenticate:", error);
      return true;
    }
  }
);

const fetchDataSlice = createSlice({
  name: "fetchData",
  initialState: {
    authenticated: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkAuth.fulfilled, (state, action) => {
      state.authenticated = action.payload;
    });
    builder.addCase(checkAuth.rejected, (state, action) => {
      state.authenticated = false;
    });
    builder.addCase(checkAuth.pending, (state, action) => {
      state.authenticated = false;
    });
  },
});

export default fetchDataSlice.reducer;
