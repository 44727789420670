import logo from "../images/yamaha-logo.svg";
import backgroundImage from "../images/scholtz.jpg";
import motoamericaLogo from "../images/MotoAmerica.png";
import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import Table from "./Table";
import stringHelper from "../helpers/string";
import SelectInput from "./SelectInput";
import Overlay from "./Overlay";
import { MdSettings, MdOutlineAddCircle } from "react-icons/md";
import { HiTemplate } from "react-icons/hi";
import WidgetBase from "./WidgetBase";
import ManageTemplates from "./ManageTemplates";
import PinnedTemplates from "./PinnedTemplates";
import Drawer from "./Drawer";

const lapSummaryTableCols = [
  { Accessor: "position", Header: "POS" },
  //{ Accessor: "lap", Header: "LAP" },
  { Accessor: "number", Header: "NUM" },
  {
    Accessor: "name",
    Header: "NAME",
    Mutate: (value) => stringHelper.abbreviateName(value),
  },
  { Accessor: "make", Header: "MAKE" },
  {
    Accessor: "best_lap",
    Header: "BEST LAP",
    Mutate: (value) =>
      stringHelper.formatTime(stringHelper.safeParseFloat(value)),
  },
  {
    Accessor: "diff",
    Header: "DIFF",
    Mutate: (value) => stringHelper.safeParseFloat(value),
  },
  {
    Accessor: "gap",
    Header: "GAP",
    Mutate: (value) => stringHelper.safeParseFloat(value),
  },
  {
    Accessor: "last_lap",
    Header: "LAST LAP",
    Mutate: (value) =>
      stringHelper.formatTime(stringHelper.safeParseFloat(value)),
  },
  {
    Accessor: "current_s1",
    Header: "S1",
    FallbackAccessor: "s1",
    Mutate: (value) => stringHelper.safeParseFloat(value),
    BorderComparator: { Accessor: "current_sector", Value: 1 },
  },
  {
    Accessor: "current_s2",
    Header: "S2",
    FallbackAccessor: "s2",
    Mutate: (value) => stringHelper.safeParseFloat(value),
    BorderComparator: { Accessor: "current_sector", Value: 2 },
  },
  {
    Accessor: "current_s3",
    Header: "S3",
    FallbackAccessor: "s3",
    Mutate: (value) => stringHelper.safeParseFloat(value),
    BorderComparator: { Accessor: "current_sector", Value: 3 },
  },
  {
    Accessor: "current_s4",
    Header: "S4",
    FallbackAccessor: "s4",
    Mutate: (value) => stringHelper.safeParseFloat(value),
    BorderComparator: { Accessor: "current_sector", Value: 4 },
  },
  {
    Accessor: "current_s5",
    Header: "S5",
    FallbackAccessor: "s5",
    Mutate: (value) => stringHelper.safeParseFloat(value),
    BorderComparator: { Accessor: "current_sector", Value: 5 },
  },
  {
    Accessor: "current_speed",
    Header: "SPEED",
    FallbackAccessor: "speed",
    Mutate: (value) => stringHelper.safeParseFloat(value),
  },
];

function LiveTiming() {
  const [isTemplatesDrawerOpen, setIsTemplatesDrawerOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isRaceSettingsOpen, setRaceSettingsOpen] = useState(false);
  const [isCompareRaceSettingsOpen, setCompareRaceSettingsOpen] =
    useState(false);
  const {
    selectedRacer,
    isRevertWindowOpen,
    dataColumns,
    setDataColumns,
    tableRows,
    setTableRows,
    showTireSection,
    setShowTireSection,
    race,
    races,
    selectedRaceId,
    setSelectedRaceId,
    lapSummary,
    setSelectedRacer,
    showPinnedTemplateButtons,
    setShowPinnedTemplateButtons,
  } = useContext(AppContext);

  const highlightRowRules = [{ Accessor: "name", Value: selectedRacer }];

  return (
    <div className="App text-2xl">
      <header className="p-4 grid grid-cols-3 items-center">
        <div className="col-span-2 flex gap-3">
          <div>
            <img src={logo} className="h-10 sm:h-14"></img>
          </div>
          <div>
            <img src={motoamericaLogo} className="h-11 sm:h-14"></img>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <MdSettings
            onClick={() => setSettingsOpen(true)}
            className="text-3xl cursor-pointer inline-block"
          />
          <HiTemplate
            onClick={() => setIsTemplatesDrawerOpen(true)}
            className="text-3xl cursor-pointer inline-block"
          />
          <Overlay
            open={isSettingsOpen}
            setOpen={setSettingsOpen}
            className="p-6"
            title="App Settings"
          >
            <SelectInput
              label="Race"
              className="mt-4"
              options={races.map((race) => ({
                value: race.id,
                name: `${race.location} ${race.name}`,
              }))}
              selected={selectedRaceId}
              onChange={(e) => setSelectedRaceId(e)}
            />
            <SelectInput
              label="Default Racer"
              className="mt-4"
              options={lapSummary.map((racer) => ({ value: racer.name }))}
              selected={selectedRacer}
              onChange={(e) => setSelectedRacer(e)}
            />
            <SelectInput
              label="Show Pinned Template Buttons"
              className="mt-4"
              options={[true, false].map((value) => ({
                value: value,
                name: value ? "Show" : "Hide",
              }))}
              selected={showPinnedTemplateButtons}
              onChange={(e) => setShowPinnedTemplateButtons(e)}
            />
            <SelectInput
              label="Sections Available"
              className="mt-4"
              options={[...Array(24).keys()]
                .map((i) => i + 1)
                .map((value) => ({ value: value }))}
              selected={dataColumns}
              onChange={(e) => setDataColumns(e)}
            />
            <SelectInput
              label="Riders Positions Shown in Table"
              className="mt-4"
              options={[...Array(35).keys()]
                .map((i) => i + 1)
                .map((value) => ({ value: value }))}
              selected={tableRows}
              onChange={(e) => setTableRows(e)}
            />
            <SelectInput
              label="Show Tire Section"
              className="mt-4"
              options={[true, false].map((value) => ({
                value: value,
                name: value ? "Show" : "Hide",
              }))}
              selected={showTireSection}
              onChange={(e) => setShowTireSection(e)}
            />
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between">
              <button
                type="button"
                className="mb-3 inline-flex w-full justify-center rounded-lg bg-emerald-500 py-2 sm:px-3 sm:py-2 text-lg sm:text-sm font-semibold text-neutral-100 shadow-sm hover:bg-emerald-600 sm:w-auto sm:ml-3"
                onClick={() => setSettingsOpen(false)}
              >
                Save
              </button>
            </div>
          </Overlay>
        </div>
      </header>
      <main className="p-4 min-h-screen relative">
        <Drawer
          title="Templates"
          open={isTemplatesDrawerOpen || isRevertWindowOpen}
          setOpen={setIsTemplatesDrawerOpen}
        >
          <ManageTemplates setOpen={setIsTemplatesDrawerOpen} />
        </Drawer>
        <div
          style={{ backgroundImage: `url(${backgroundImage})` }}
          className="absolute inset-0 bg-cover bg-center blur-md"
        ></div>
        <div className="relative z-10">
          <div
            onClick={() => setRaceSettingsOpen(true)}
            className="cursor-pointer text-xl text-white py-3"
          >
            <span className="font-bold">Current Session Live Timing</span>{" "}
            <br className="sm:hidden" />
            <span className="italic">
              <MdOutlineAddCircle className="inline-block" /> {race.location}{" "}
              {race.name} - {selectedRacer}
            </span>
          </div>
          <Overlay
            open={isRaceSettingsOpen}
            setOpen={setRaceSettingsOpen}
            className="p-6"
            title="Racer Selection"
          >
            <SelectInput
              label="Race"
              className="mt-4"
              options={races.map((race) => ({
                value: race.id,
                name: `${race.location} ${race.name}`,
              }))}
              selected={selectedRaceId}
              onChange={(e) => setSelectedRaceId(e)}
            />
            <SelectInput
              label="Default Racer"
              className="mt-4"
              options={lapSummary.map((racer) => ({ value: racer.name }))}
              selected={selectedRacer}
              onChange={(e) => setSelectedRacer(e)}
            />
          </Overlay>
          <PinnedTemplates setOpen={setIsTemplatesDrawerOpen} />
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4  gap-4 ">
            {/* <div className="bg-blue-500 p-6 text-white">Item 1</div> */}

            {[...Array(parseInt(dataColumns))].map((_, colIndex) => (
              <WidgetBase key={colIndex} id={colIndex} />
            ))}
          </div>
          {/* <div className={`grid sm:grid-cols-12 grid-cols-2 gap-4`}>
            {[...Array(parseInt(dataColumns))].map((_, colIndex) => (
              <WidgetBase key={colIndex} id={colIndex} />
            ))}
          </div> */}
          <div
            onClick={() => setCompareRaceSettingsOpen(true)}
            className="hidden cursor-pointer text-xl text-white py-3"
          >
            <span className="text-bold">Past Data Loaded In</span>{" "}
            <span className="italic">
              <MdOutlineAddCircle className="inline-block" /> Historical Race
              Name
            </span>
          </div>
          <Overlay
            open={isCompareRaceSettingsOpen}
            setOpen={setCompareRaceSettingsOpen}
            title="Historical Race Selection"
          >
            Select a historical race.
          </Overlay>

          <div className="grid grid-cols-12 gap-4">
            <div
              className={`shadow-inner shadow-gray-400 bg-opacity-80 bg-gray-500 rounded-2xl ${
                showTireSection ? `col-span-9` : `col-span-12`
              } mt-10 p-4`}
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-white text-lg">
                <div>
                  <b>{race.location}</b> - {race.event}
                </div>
                <div className="text-left sm:text-right">
                  <p className="text-[16px]">{race.status_details}</p>
                </div>
              </div>
              <Table
                data={lapSummary.slice(0, tableRows)}
                columns={lapSummaryTableCols}
                highlightRowRules={highlightRowRules}
              />
            </div>
            <div
              className={`${
                showTireSection
                  ? `shadow-inner shadow-gray-400 bg-opacity-80 bg-gray-500 rounded-2xl text-white mt-10 p-4 col-span-3`
                  : `hidden`
              }`}
            >
              Tires
              <br />
              Coming Soon
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default LiveTiming;
